



























































































import {Component, Vue} from "vue-property-decorator";
import {OrderType} from "@/models/Order";
import OrderService from "@/services/OrderService";
import Product from "@/models/Product";
import ProductService from "@/services/ProductService";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import SessionModule from "@/store/SessionModule";
import LangModule from "@/store/LangModule";
import UneditableProductListComponent from "@/components/UneditableProductListComponent.vue";
import {AuthorityName} from "@/models/Authority";
import BudgetService from "@/services/BudgetService";
import Budget, {BudgetType} from "@/models/Budget";

@Component({components:{ UneditableProductListComponent }})
export default class OrderDetailView extends Vue {
	lang: any = getModule(LangModule).lang
	budget: Budget = new Budget()
	items: Product[] = [new Product()]
	products: Product[] = []
	pvp: boolean = false
	headers = [
		{ text: this.lang.image, value: 'picture' },
		{ text: this.lang.reference, value: 'product.connectorId' },
		{ text: this.lang.product, value: 'name' },
		{ text: this.lang.quantity, value: 'quantity' },
		{text: this.lang.pvp, value: 'commercialRate'},
		{text: this.lang.total, value: 'pvpTaxBase'},
	]
	orderTypeFinish: OrderType = OrderType.FINISH
	orderTypeSend: OrderType = OrderType.SEND
	orderTypeWaiting: OrderType = OrderType.WAITING
	sessionModule: SessionModule = getModule(SessionModule)
	isDistributor = this.sessionModule.session.authorities.map(e => e.title == "distributor")[0]
	isManager: boolean = this.sessionModule.session.authorities[0].name == AuthorityName.MANAGER


	get budgetType() { return BudgetType }
	get rateId() { return this.sessionModule.session.user.rateId }
	get isDisable(): boolean { return this.budget.budgetType != BudgetType.DRAFT }

	created()  {
		this.refresh()
		ProductService.getProducts(this, this.products, 0, 100, "", null, null, null, null)
	}

	refresh() {
		BudgetService.getBudget(this, Number(this.$route.params.orderId))
	}

	sendOrder() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning,
			 this.lang.sendOrderQuestion,
			 () => BudgetService.sendBudget(this, this.budget.id!)
		))
	}

	cloneOrder() {
		OrderService.cloneOrder(this, this.budget)
	}

	getOrderPdf() {
		OrderService.getOrderPdf(this, this.budget)
	}

	//Precio del comercial
	get totalPvp() {
		let total = 0
		const values = this.budget.budgetDetails!.map(e => (e.product!.commercialRate! * e.quantity!))
		values.forEach(e => {total = total + e})
		return total
	}

	//Precio del distribuidor
	get total() {
		let total = 0
		const values = this.budget.budgetDetails!.map(e => Number(e.product!.rates![this.rateId! - 1].price) * Number(e.quantity))
		values.forEach(e => {total = total + e })
		return total
	}

	get totalQuantity(): number {
		let total = 0
		const values = this.budget.budgetDetails!.map(e => Number(e.quantity!))
		values.forEach(e => {total = total + e})
		return total
	}

}
